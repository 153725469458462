import { useState } from "react";
import { useQueryParams } from "src/helpers/useQueryParams";
import { FORM_TYPE, AuthRouteProps } from "./types";
import { CompletePassword } from "./CompletePassword";
import { ForgotPassword } from "./ForgotPassword";
import { Login } from "./Login";

export function Auth() {
  const query = useQueryParams();
  const [formType, setFormType] = useState<FORM_TYPE>((query?.formType as FORM_TYPE) || "signin");

  return <SwitchAuthRoute formType={formType} setFormType={setFormType} />;
}

function SwitchAuthRoute(props: AuthRouteProps) {
  switch (props.formType) {
    case "signin":
      return <Login {...props} />;
    case "completePassword":
      return <CompletePassword {...props} />;
    case "forgotPassword":
      return <ForgotPassword {...props} />;
    default:
      return null;
  }
}
