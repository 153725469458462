import { useState } from "react";
import { Image } from "src/common-ui";
import { Media as MediaType } from "@kards/types";

export function Media({ media }: { media: MediaType }) {
  switch (media?.type) {
    case "video":
      return <ImageGif image={media} size="medium" />;
    case "image":
    default:
      return <Image image={media} size="medium" />;
  }
}
const sizeRange = {
  small: 60,
  medium: 295,
  big: 395,
};

export function ImageGif({ image, style = {}, size, ...props }) {
  const crop = `${image?.crop?.width || 0},${image?.crop?.height || 0},x${image?.crop?.x || 0},y${image?.crop?.y || 0}`;
  const src = `${image?.gifURL}?crop=${crop}&width=${sizeRange[size]}`;

  const [loading, setLoading] = useState(true);
  const showLoader = !image?.id || loading;

  return (
    <>
      {image?.id && (
        <img
          width="100%"
          height="auto"
          {...props}
          style={{ ...style, display: !showLoader ? "inline" : "none" }}
          src={src}
          alt={image?.name}
          onLoad={() => setLoading(false)}
        />
      )}
      <img
        width="100%"
        height="auto"
        {...props}
        style={{ ...style, display: showLoader ? "inline" : "none" }}
        src={"/img/placeholderImage.png"}
        alt="placeholderImage"
      />
    </>
  );
}
