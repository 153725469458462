import { ReactNode } from "react";
import { Box } from "reflexbox";
import { TextGreyBold, Button } from "src/common-ui";
import styled from "styled-components";
import { Establishment } from "@kards/types";

export type StepperPros = Omit<OnBoardingLayoutProps, "children" | "title">;

export type OnBoardingLayoutProps = {
  info?: string;
  disabledNextBtn?: boolean;
  onBack?: () => void;
  onNext?: () => void;
  isComplete?: boolean;
  plan?: Establishment["plan"];
  title: string;
  children: ReactNode;
};

export function OnBoardingLayout({
  info = "POUR COMMENCER",
  disabledNextBtn,
  onBack,
  onNext,
  isComplete,
  title,
  children,
}: OnBoardingLayoutProps) {
  return (
    <Wrapper sx={{ position: "relative" }}>
      <Box p={2} sx={{ position: "absolute", left: "20px", top: "20px" }}>
        <img src="/img/kards/logo.svg" alt="logo" width="100px" />
      </Box>
      <Box height="80%" p={4} m={3} mb={0} display="flex" justifyContent="center" sx={{ boxSizing: "border-box" }}>
        <Box flex=".3" flexDirection="column" display="flex" justifyContent="center" alignItems="flex-start">
          <TextGreyBold style={{ marginBottom: "20px" }} fontSize="1em">
            {info}
          </TextGreyBold>
          <TextGreyBold style={{ margin: "10px" }} fontSize="1.5em">
            {title}
          </TextGreyBold>
        </Box>
        <Box height="80%" width="1px" backgroundColor="secondary.main" />
        <Box flex=".7" display="flex" justifyContent="center">
          {children}
        </Box>
      </Box>
      <Box
        pb={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="20%"
        sx={{ boxSizing: "border-box" }}
      >
        {onBack && (
          <Button style={{ margin: "20px", fontSize: "1.2em" }} onClick={onBack}>
            Retour
          </Button>
        )}
        {onNext && (
          <Button style={{ margin: "20px", fontSize: "1.2em" }} onClick={onNext} disabled={disabledNextBtn}>
            Continuer
          </Button>
        )}
        {isComplete && (
          <Button style={{ margin: "20px", fontSize: "1.2em" }} disabled={disabledNextBtn} type="submit">
            Terminer
          </Button>
        )}
      </Box>
    </Wrapper>
  );
}

export const Wrapper = styled(Box)`
  height: 100vh;
  display: flex;
  justify-content: center;

  flex-direction: column;
  background-color: white;
  box-sizing: border-box;
  overflow: auto;
`;
