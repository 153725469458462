import { useState, useEffect } from "react";
import { Box } from "reflexbox";
import DatePickerUI from "react-datepicker";
import { Label, Input } from "src/common-ui/Form";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";

type DatePickerProps = {
  label?: string;
  value?: Date;
  onChange: (dateInput: Date) => void;
} & Omit<React.ComponentProps<typeof DatePickerUI>, "onChange">;

export function DatePicker({ label, onChange, value, ...props }: DatePickerProps) {
  const [dateInput, setDate] = useState<Date>(value);

  useEffect(() => {
    setDate(value);
  }, [value]);

  return (
    <Box display="flex" flexDirection="column">
      <Label pl={1}>{label}</Label>
      <DatePickerUI
        autoComplete="off"
        locale={fr}
        name="scheduledAt"
        todayButton="Aujourd'hui"
        calendarClassName="datePickerTime"
        dateFormat="dd/MM/yyyy"
        selected={dateInput}
        onCalendarClose={() => onChange(dateInput)}
        onChange={(date: Date) => setDate(date)}
        customInput={<Input style={{ backgroundColor: "#cecece" }} />}
        {...props}
      />
    </Box>
  );
}
