import { Box } from "reflexbox";
import { ErrorField, Label } from "src/common-ui/Form";
import ReactPhoneInput from "react-phone-input-2";
import { useField } from "react-final-form";

export function PhoneNumberField() {
  const { input, meta } = useField("phone");

  console.log("meta", meta);
  return (
    <Box mb={1} sx={{ position: "relative" }} width="60%">
      <label>
        <Label error={meta.visited && meta.error}>Numéro de téléphone (+33 6 XX XX XX XX)</Label>
        <ReactPhoneInput
          inputStyle={{
            height: "2.5em",
            border: "1px solid rgb(208, 213, 232)",
            borderRadius: "0px",
            outline: "none",
            fontSize: ".9em",
            color: "#777777 !important",
            width: "100%",
            boxSizing: "border-box",
          }}
          copyNumbersOnly={false}
          data-cy="phone-number"
          country="fr"
          onlyCountries={["fr"]}
          value={input.value}
          onChange={input.onChange}
        />
        <ErrorField meta={meta} />
      </label>
    </Box>
  );
}
