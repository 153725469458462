import { Flex, Box } from "reflexbox";
import { IoCloseSharp } from "react-icons/io5";

const roleMapping = {
  info: "status",
  success: "status",
  error: "alert",
};

const colors = {
  success: "#2ED5C3",
  error: "#FF4B4B",
  info: "blue.main",
};

type NotificationUiProps = {
  show: boolean;
  type: "info" | "success" | "error";
  onClick?: () => void;
  children: React.ReactNode;
};

export function NotificationUi({ show, type, onClick, children }: NotificationUiProps) {
  return (
    <Flex
      sx={{
        position: "fixed",
        top: "20px",
        right: show ? "20px" : "-250px",
        borderRadius: "5px",
        boxShadow: "6px 6px 25px 9px rgba(0,0,0,0.4)",
        zIndex: show ? 4000 : -1,
        opacity: show ? 1 : 0,
        transition: "all ease-out 250ms",
      }}
      alignItems="center"
      px={3}
      py={2}
      bg={colors[type]}
      role={roleMapping[type]}
      aria-label="notification"
    >
      <Box color="white.main" mr={1}>
        {children}
      </Box>

      <button type="button" onClick={onClick} style={{ height: "16px", cursor: "pointer" }} aria-hidden="true">
        <IoCloseSharp fontSize="16px" color="white" />
      </button>
    </Flex>
  );
}
