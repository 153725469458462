import { useField } from "react-final-form";
import MSelect from "react-select";
import AsyncSelect from "react-select/async";
import { Select } from "./Select";
import { Label } from "./Label";
import { ErrorField } from "./ErrorField";
import { Box } from "reflexbox";
import { useThemeUI } from "theme-ui";

type SelectProps = {
  name: string;
  label?: string;
  required?: boolean;
} & React.ComponentProps<typeof MSelect>;

export const styles = {
  control: (styles: React.CSSProperties) => ({
    ...styles,
    width: "300px",
  }),
};

export function SelectField({ name, label, required = false, options, style = {}, ...props }: SelectProps) {
  const { input, meta } = useField(name);

  return (
    <Box mb={2} sx={{ position: "relative" }} style={{ ...style }}>
      <label>
        {label && <Label color={props?.color}>{`${label}${required ? "*" : ""}`}</Label>}
        <Select
          defaultValue={input.value}
          options={options}
          value={options.filter((option) => option.value === input.value)}
          onChange={(v) => input.onChange(v.value)}
          {...props}
        />
      </label>
      <ErrorField meta={meta} />
    </Box>
  );
}

export function SelectFieldMulti({ name, label, required = false, options, style = {}, ...props }: SelectProps) {
  const { input, meta } = useField(name);

  const { theme }: any = useThemeUI();

  const handleChange = async (_, action) => {
    if (action.action === "select-option") {
      input.onChange([...input.value, action.option.value]);
    }
    if (action.action === "remove-value") {
      input.onChange([...input.value.filter((v) => v !== action.removedValue.value)]);
    }
  };

  return (
    <Box mb={2} style={{ ...style }}>
      <label>
        <Label>{`${label}${required ? "*" : ""}`}</Label>
        <Select
          isMulti
          value={options?.filter((op) => input?.value?.includes(op.value))}
          defaultValue={input.value}
          onChange={handleChange}
          options={options}
          styles={{
            control: () => ({
              border: "1px solid rgb(208, 213, 232)",
              display: "flex",
              backgroundColor: theme?.colors?.primary?.white_to_grey,
              fontSize: ".9em",
              color: theme?.colors?.primary?.dark_to_white,
              borderRadius: "4px",
              paddingLeft: "4px",
            }),
            valueContainer: () => ({
              width: "90%",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }),
            singleValue: () => ({
              color: theme?.colors?.white?.main,
              fontWeight: 400,
            }),
            input: () => ({
              margin: 0,
              paddingBottom: 0,
              paddingTop: 0,
            }),
            menu: () => ({
              border: "1px solid rgb(208, 213, 232)",
              position: "absolute",
              zIndex: 10000000,
              marginTop: "5px",
              width: "100%",
              borderRadius: "4px",
              overflow: "hidden",
            }),
            menuList: () => ({
              padding: 0,
              backgroundColor: theme?.colors?.primary?.white_to_grey,
              overflow: "scroll",
              maxHeight: "300px",
            }),
            placeholder: () => ({
              color: theme?.colors?.primary?.dark_to_white,
              fontWeight: 400,
            }),
            option: () => ({
              backgroundColor: theme?.colors?.primary?.white_to_grey,
              padding: "5px",
              margin: "0px 3px",
              color: theme?.colors?.primary.blue_to_white,
              "&:hover": {
                color: theme?.colors?.white?.main,
                background: theme?.colors?.blue.main,
              },
            }),
            multiValueLabel: (styles) => ({
              ...styles,
              color: theme?.colors?.white?.main,
            }),
            multiValueRemove: (styles) => ({
              ...styles,
              color: theme?.colors?.white?.main,
              cursor: "pointer",
              "&:hover": {
                background: theme?.colors?.blue.main,
                color: theme?.colors?.white?.main,
              },
            }),
            multiValue: (styles) => ({
              ...styles,
              background: theme?.colors?.blue.main,
              color: theme?.colors?.white?.main,
              padding: "4px 14px",
              borderRadius: "24px",
              fontSize: "14px",
            }),
          }}
          {...props}
        />
        <ErrorField meta={meta} />
      </label>
    </Box>
  );
}

export function AutocompleteAsync({ name, label, required, loadOptions, validate, showError = true, ...props }) {
  const { input, meta } = useField(name, { validate });
  return (
    <Box width="100%" mb={2} color="#343a40">
      <label>
        {label && <Label>{`${label}${required ? "*" : ""}`}</Label>}
        <AsyncSelect
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          defaultInputValue={input.value}
          onChange={(v: any) => input.onChange(v.value)}
          {...props}
        />
        {showError && <ErrorField meta={meta} />}
      </label>
    </Box>
  );
}
