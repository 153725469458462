import { NavLink as NavLinkRaw } from "react-router-dom";
import { Box, Flex } from "reflexbox";
import { useState } from "react";

export function NavLink({ to, bg = "menuColor", children, ...props }) {
  const [active, setActive] = useState<boolean>();

  return (
    <NavLinkRaw
      to={to}
      isActive={(match) => {
        if (!match) {
          setActive(false);
          return false;
        }
        setActive(true);
        return true;
      }}
      style={{
        paddingTop: active ? "7px" : 0,
        transition: "all linear 100ms",
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
      {...props}
    >
      <Flex
        flexDirection="column"
        p={2}
        bg={bg}
        alignItems="center"
        opacity={active ? "1" : "0.6"}
        sx={{
          position: "relative",
          borderRadius: "24px",
          boxShadow: active ? "0px 6px 18px -5px rgba(125,155,250,0.85)" : "none",
          transition: "all linear 100ms",
        }}
      >
        {children}
        <Box
          bg={bg}
          width="35px"
          height="35px"
          sx={{
            borderRadius: "5px",
            position: "absolute",
            transform: "rotate(45deg)",
            bottom: active ? "-12px" : "6px",
            boxShadow: active ? "13px 13px 12px -12px rgb(125 155 250 / 85%)" : "none",
            zIndex: active ? 1 : -1,
            transition: "all ease-out 100ms",
          }}
        />
      </Flex>
    </NavLinkRaw>
  );
}
