import { Route, Switch } from "react-router-dom";
import { Auth } from "src/auth";
import { ChoiceAccountType } from "src/auth/CreateAccount";
import { OnBoarding } from "src/onboarding";

export function LoginRoutes() {
  return (
    <Switch>
      <Route path="/login" component={Auth} />
      <Route path="/create-account" component={ChoiceAccountType} />
      <Route path="/onboarding/:establishmentId" exact component={OnBoarding} />
    </Switch>
  );
}
