import { Flex, Box } from "reflexbox";
import { AiOutlineSearch } from "react-icons/ai";

export function SearchInput(props) {
  return (
    <Flex alignItems="center" sx={{ position: "relative" }} width="100%">
      <AiOutlineSearch color="grey" fontSize="20px" style={{ position: "absolute", left: "15px" }} />
      <Box
        as="input"
        sx={{ borderRadius: "24px" }}
        bg="input"
        width="100%"
        fontFamily="inherit"
        fontSize={"14px"}
        p={"10px"}
        pl={5}
        placeholder="Recherche..."
        {...props}
      />
    </Flex>
  );
}
