import { useState } from "react";
import { Box } from "reflexbox";
import { TitleGreyBold, Button } from "src/common-ui";
import { TextField, FormFinal } from "src/common-ui/Form";
import { ContainerForm } from "./ContainerForm";
import { auth } from "src/firebase";
import { AuthRouteProps } from "./types";

type FormValues = {
  password?: string;
  confirmPassword?: string;
};

export function CompletePassword({ setFormType, formType }: AuthRouteProps) {
  const [loading, setLoading] = useState(false);

  function getParameterByName(params) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(params);
  }

  const handleResetPassword = async ({ password }) => {
    setLoading(true);
    const actionCode = getParameterByName("oobCode");
    // Verify the password reset code is valid.
    auth
      .verifyPasswordResetCode(actionCode)
      .then(function() {
        // Save the new password.
        auth
          .confirmPasswordReset(actionCode, password)
          .then(function() {
            // Password reset has been confirmed and new password updated.
            console.log("PASSWORD RESET DONE");
            // TODO: Display a link back to the app, or sign-in the user directly
            // if the page belongs to the same domain as the app:
            // auth.signInWithEmailAndPassword(accountEmail, newPassword);

            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
          })
          .catch(function() {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
          });
      })
      .catch(function() {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
      });
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.password) {
      errors.password = "Champs requis";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Champs requis";
    }
    return errors;
  };

  return (
    <ContainerForm loading={loading} setFormType={setFormType} formType={formType}>
      <FormFinal validate={validate} onSubmit={handleResetPassword}>
        {() => (
          <>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mb="10px"
              sx={{ borderBottom: "1px solid #cecece" }}
            >
              <Box p={1}>
                <img src="/img/kards/logo.svg" alt="logo" width="100px" />
              </Box>
              <TitleGreyBold>Saisissez votre mot de passe</TitleGreyBold>
              <Box pb={1} />
            </Box>
            <Box p={1} />
            <TextField
              name="password"
              data-cy="input-password"
              label={"Mot de passe"}
              type="text"
              disabled={loading}
              width="100%"
            />
            <Box p={1} />
            <TextField
              name="confirmPassword"
              data-cy="input-password-confirm"
              label={"Confirmer le mot de passe"}
              type="text"
              disabled={loading}
              width="100%"
            />
            <Box p={1} />
            <Box pb={2} display="flex" alignItems="center" justifyContent="center" width="100%">
              <Button data-cy="btn-signUp" type="submit" disabled={loading}>
                Valider
              </Button>
              <Box p={1} />
              <Button data-cy="btn-back" onClick={() => setFormType("signin")}>
                Retour
              </Button>
            </Box>
          </>
        )}
      </FormFinal>
    </ContainerForm>
  );
}
