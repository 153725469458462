import { useState } from "react";
import { Image } from "@kards/types";

const sizeRange = {
  small: 60,
  medium: 295,
  big: 395,
};

type PictureProps = {
  style?: React.CSSProperties;
  image: Image;
  size: keyof typeof sizeRange;
  width?: string;
  maxWidth?: string;
  height?: string;
};

export function Picture({ image, style = {}, size, ...props }: PictureProps) {
  const crop = `${image?.crop?.width || 0},${image?.crop?.height || 0},x${image?.crop?.x || 0},y${image?.crop?.y || 0}`;
  const src = `${window.kardsConfiguration.hostApi}${image?.imageURL}?crop=${crop}&width=${sizeRange[size]}`;

  const [loading, setLoading] = useState(true);
  const showLoader = !image?.id || loading;

  return (
    <>
      {image?.id && (
        <img
          width="100%"
          height="auto"
          {...props}
          style={{ ...style, display: !showLoader ? "inline" : "none" }}
          src={src}
          alt={image?.name}
          onLoad={() => setLoading(false)}
        />
      )}
      <img
        width="100%"
        height="auto"
        {...props}
        style={{ ...style, display: showLoader ? "inline" : "none" }}
        src={"/img/placeholderImage.png"}
        alt="placeholderImage"
      />
    </>
  );
}
