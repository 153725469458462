import { Flex } from "reflexbox";
import { darken, lighten } from "@theme-ui/color";
import { Loader } from "./Loading";

function createRipple(event) {
  const button = event.currentTarget;

  const circle = document.createElement("span");
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  circle.style.width = circle.style.height = `${diameter}px`;
  circle.classList.add("ripple");

  const ripple = button.getElementsByClassName("ripple")[0];

  if (ripple) {
    ripple.remove();
  }

  button.appendChild(circle);
}

export type ButtonProps = {
  onClick?: any;
  variant?: "rounded" | "square";
  size?: "normal" | "small" | "large";
  tooltip?: string;
  width?: string | number;
  loading?: boolean;
  disabled?: boolean;
  progress?: number;
  children?: React.ReactNode | string;
  type?: "button" | "submit" | "reset";
  id?: any;
} & React.ComponentProps<typeof Flex>;

export function Button({
  backgroundColor = "primary.main",
  color = "white.main",
  children,
  onClick,
  loading,
  progress,
  size = "normal",
  variant = "rounded",
  ...props
}: ButtonProps) {
  const onClickEvent = (e) => {
    createRipple(e);
    onClick && onClick();
  };

  const getSize = {
    small: { px: "9px", py: "4px" },
    normal: { px: "21px", py: "11px" },
    large: { px: "37px", py: "13px" },
  };
  const getVariant = {
    rounded: "30px",
    square: "8px",
  };

  const isProgress = Boolean(progress) && progress !== 100;

  return (
    <Flex
      as="button"
      type="button"
      color={color}
      backgroundColor={backgroundColor}
      className="ripple littleBounced"
      justifyContent="center"
      alignItems="center"
      fontSize="16px"
      onClick={(e) => onClickEvent(e)}
      {...getSize[size]}
      sx={{
        position: "relative",
        overflow: "hidden",
        transition: "all linear 200ms",
        borderRadius: getVariant[variant],
        // boxShadow: "0 0 0.5rem rgba(0, 0, 0, 0.3)",
        cursor: "pointer",
        "&:hover": {
          color: darken(color),
          background: darken(props.bg || backgroundColor, ".05"),
        },
        "&:disabled": {
          cursor: "default",
          background: lighten(props.bg || backgroundColor, ".10"),
        },
      }}
      {...props}
    >
      {isProgress && (
        <Flex
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
          sx={{ position: "absolute", zIndex: 1 }}
        >
          <Flex
            bg="blue.main"
            height="100%"
            width={`${progress}%`}
            alignItems="center"
            justifyContent="center"
            sx={{ position: "absolute", zIndex: 1, left: 0 }}
          />
          <Flex
            height="100%"
            width="100%"
            alignItems="center"
            justifyContent="center"
            sx={{ position: "absolute", zIndex: 2 }}
          >
            {progress}%
          </Flex>
        </Flex>
      )}
      {loading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          backgroundColor={backgroundColor}
          sx={{ position: "absolute", zIndex: 1 }}
        >
          <Loader size="15px" color="white.main" />
        </Flex>
      )}
      <Flex justifyContent="center" alignItems="center" width="100%" height="100%" sx={{ opacity: isProgress ? 0 : 1 }}>
        {children}
      </Flex>
    </Flex>
  );
}
