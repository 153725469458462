import { Flex } from "reflexbox";

type BoxProps = React.ComponentProps<typeof Flex>;

export const Card = (props: BoxProps) => (
  <Flex
    backgroundColor="primary.white_to_dark"
    p={3}
    sx={{
      boxShadow: "0px 18px 24px -19px rgba(34,37,59,0.26)",
      borderRadius: "28px",
    }}
    {...props}
  />
);
