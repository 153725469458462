import styled from "styled-components";
import { Box, BoxProps } from "reflexbox";

const TagStyled = styled(Box)<{ selected?: boolean; bg: string }>`
  display: flex;
  justify-content: center;
  border-radius: 35px;
  cursor: pointer;
  min-width: 100px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  border: ${(props) => (props.selected ? `5px solid #403FCE` : `5px solid transparent`)};
  background-color: ${(props) => props.bg || "white"};
`;

export function Tag({ variant, ...props }: BoxProps & { variant?: "list"; selected?: boolean } & any) {
  if (variant === "list")
    return (
      <TagStyled
        color="#fff"
        bg="primary.light"
        fontWeight="bold"
        fontSize={12}
        style={{ boxShadow: "none" }}
        {...props}
      />
    );

  return <TagStyled p="10px" {...props} />;
}
