import { ReactNode, useState } from "react";
import { Button } from "src/common-ui";

export type FileData = {
  data: string | ArrayBuffer;
  name: string;
  file: File;
};

export type FileUploadProps = {
  label: string | ReactNode;
  multiple?: boolean;
  loading?: boolean;
  readAs?: string;
  progress?: number;
  variant?: React.ComponentProps<typeof Button>["variant"];
  onUpload: (results: FileData | FileData[]) => void;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export function FileUpload({
  label,
  multiple = false,
  loading = false,
  progress,
  readAs = undefined,
  onUpload,
  variant,
  ...props
}: FileUploadProps) {
  const [value, setValue] = useState<any>();

  const onChangeImage = async (e) => {
    e.preventDefault();
    const files = e?.target?.files || e?.dataTransfer?.files;

    if (multiple) {
      const results: FileData[] = [];
      for (const file of files) {
        const result = await read(file, readAs);
        results.push(result);
      }

      onUpload(results);
    } else {
      const file = files?.[0];
      const result = await read(file, readAs);
      onUpload(result);
    }
    setValue("");
  };

  const isProgress = Boolean(progress) && progress !== 100;

  return (
    <Button
      data-cy="btn-upload-file"
      loading={loading}
      progress={progress}
      variant={variant}
      disabled={props.disabled || isProgress || loading}
    >
      <label style={{ cursor: props.disabled ? "default" : "pointer", display: "flex" }}>
        {label}
        <input
          id="input-multiple"
          type="file"
          data-cy="file-input"
          style={{ display: "none" }}
          onChange={onChangeImage}
          multiple={multiple}
          {...props}
          value={value}
        />
      </label>
    </Button>
  );
}

function read(file: File, readAs = "readAsText"): Promise<FileData> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader[readAs](file);
    reader.onload = function() {
      resolve({ data: reader.result, name: file?.name, file });
    };
    reader.onerror = function(error) {
      console.error("error", error);

      reject(error);
    };
  });
}
