import { useState, useRef, useEffect } from "react";
import { Box } from "reflexbox";
import { Text, Button } from "src/common-ui";
import { Flex } from "reflexbox";
import { useTransition, useSpring, useChain, config } from "react-spring";
import { animated } from "react-spring";
import styled from "styled-components";
import { Select } from "src/common-ui/Form";
import { useThemeUI } from "theme-ui";
import { db } from "../firebase";
import { useHistory } from "react-router";
import { useEstablishment } from "../helpers/useEstablishment";

const MainContainer = styled(animated.div)<any>`
  position: relative;
  display: grid;
  grid-template-rows: 29% 51% 20%;
  border-radius: 28px;
  box-shadow: 0px 18px 24px -19px rgba(34, 37, 59, 0.26);
  will-change: width, height;
  background: white;
  max-width: 1200px;
  max-height: 800px;
`;

const ContainerItems = styled(animated.div)<any>`
  position: relative;
  display: grid;
  grid-template-columns: 23% 50% 23%;
  grid-gap: 2%;
  padding-left: 60px;
  padding-right: 60px;
  will-change: width, height;
`;

const Item = styled(animated.div)<any>`
  width: 100%;
  height: 100%;
  background: #e0e2f4;
  border-radius: 28px;
  overflow: hidden;
  will-change: transform, opacity;
`;

const options = [
  { value: "restaurant", label: "Restaurant" },
  { value: "motel", label: "Hotel" },
  { value: "business", label: "Entreprise" },
  { value: "event", label: "Évenement" },
  { value: "camping", label: "Camping" },
];

export function OnboardingWelcome({ ...props }: any) {
  const establishmentId = useEstablishment();
  const [type, setType] = useState("restaurant");
  const history = useHistory();
  const { theme }: any = useThemeUI();
  const [openAnimate, setOpenAnimate] = useState(false);

  const positions = [{ position: 1 }, { position: 2 }, { position: 3 }];

  // ref main container
  const mainContainerRef = useRef();
  const { size: sizeMainContainer, ...restMainContainerSize }: any = useSpring({
    ref: mainContainerRef,
    config: config.stiff,
    from: { size: "0%" },
    to: { size: openAnimate ? "90%" : "0%" },
  });

  // ref container items
  const containerItemRef = useRef();
  const { size: sizeContainerItem, ...restContainerItem }: any = useSpring({
    ref: containerItemRef,
    config: config.stiff,
    from: { size: "0%" },
    to: { size: openAnimate ? "100%" : "0%" },
  });

  // ref items
  const transRef = useRef();
  const transitions = useTransition(openAnimate ? positions : [], (item) => item.position, {
    ref: transRef,
    unique: true,
    trail: 400 / positions.length,
    from: { opacity: 0, transform: "scale(0)" },
    enter: { opacity: 1, transform: "scale(1)" },
    leave: { opacity: 0, transform: "scale(0)" },
  });

  function handleChange(e) {
    setType(e?.value);
  }
  useChain(
    openAnimate ? [mainContainerRef, containerItemRef, transRef] : [mainContainerRef, transRef, containerItemRef],
  );

  useEffect(() => {
    const time = setTimeout(() => setOpenAnimate(true), 2000);
    return () => clearTimeout(time);
  }, []);

  return (
    <Box bg="#403FCE" height="100vh" width="100vw" {...props}>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" height="10vh">
        <img
          style={{ maxWidth: "180px" }}
          width="auto"
          height="40%"
          src="/img/kards/logoWhite.svg"
          onClick={() => setOpenAnimate((openAnimate) => !openAnimate)}
        />
        <Text color="white.main" fontWeight="bold" style={{ letterSpacing: "1px" }}>
          Bonjour et bienvenue sur Kards !
        </Text>
      </Flex>
      <Flex width="100vw" height="90vh" alignItems="center" justifyContent="center" flexDirection="column">
        <MainContainer
          style={{
            ...restMainContainerSize,
            width: sizeMainContainer,
            height: sizeMainContainer,
            overflow: "hidden",
          }}
        >
          <Flex flexDirection="column" justifyContent="space-between" alignItems="center" p={3}>
            <Text fontSize="1.5em" textAlign="center" width="550px" fontFamily="Mulish, sans-serif" letterSpacing="1px">
              <b>Enrichissez l’expérience </b> de vos clients avec <b>l’animation digitale</b> de vos espaces !
            </Text>
            <Flex flexDirection="column" alignItems="flex-start" justifyContent="center" width="40%">
              <Text fontWeight="bold" fontSize={1}>
                Selectionnez votre typologie de lieu
              </Text>
              <Select
                styles={{
                  container: (styles) => ({
                    width: "100%",
                    cursor: "pointer",
                    ...styles,
                  }),
                  control: () => ({
                    display: "flex",
                    backgroundColor: "transparent",
                    fontSize: ".9em",
                    color: theme?.colors?.primary?.dark_to_white,
                    borderRadius: "4px",
                    paddingLeft: "4px",
                    width: "100%",
                    border: "solid 1pt #d9dadb",
                    padding: "5px",
                  }),
                  option: (styles, { isSelected }) => ({
                    ...styles,
                    backgroundColor: isSelected
                      ? theme?.colors?.primary?.blue?.main
                      : theme?.colors?.primary?.white?.main,
                    padding: "5px",
                    margin: "0px 3px",
                    color: theme?.colors?.primary?.dark?.main,
                    "&:hover": {
                      color: theme?.colors?.white?.main,
                      background: theme?.colors?.blue?.main,
                    },
                    "&:active": {
                      backgroundColor: theme?.colors?.primary?.blue?.main,
                    },
                  }),
                }}
                options={options}
                defaultValue={{ value: "restaurant", label: "Restaurant" }}
                onChange={handleChange}
              />
            </Flex>
          </Flex>
          <ContainerItems
            style={{
              ...restContainerItem,
              width: sizeContainerItem,
              height: sizeContainerItem,
            }}
          >
            {transitions.map(({ item, props }) => (
              <Item item={item} key={item.position} style={{ ...props }}>
                <InnerItems item={item} />
              </Item>
            ))}
          </ContainerItems>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ position: "relative" }}
            onClick={async () => {
              await db
                .collection(`establishments`)
                .doc(establishmentId)
                .set({ type }, { merge: true });
              history.push(`/establishments/${establishmentId}/dashboard`);
            }}
          >
            <Button variant="square" size="large">
              Acceder a mon tableau de bord
            </Button>

            <Box sx={{ position: "absolute", right: 50, bottom: -32, zIndex: 0 }}>
              <img height="150px" width="150px" src="/img/suggestions/suggestions1.svg" />
            </Box>
          </Flex>
        </MainContainer>
      </Flex>
    </Box>
  );
}

const InnerItems = ({ item }) => {
  if (item.position === 1) return <InnerItem1 />;
  if (item.position === 2) return <InnerItem2 />;
  if (item.position === 3) return <InnerItem3 />;
  return null;
};

const InnerItem1 = () => {
  return (
    <Flex flexDirection="column" justifyContent="space-between" alignItems="center" height="100%" overflow="hidden">
      <Flex px={1} flex=".3" justifyContent="center" alignItems="center">
        <Text
          as="h2"
          textAlign="center"
          fontWeight="900"
          fontSize="1.3em"
          color="dark.main"
          fontFamily="Poppins"
          letterSpacing="1.04px"
        >
          Choisissez vos espaces
        </Text>
      </Flex>
      <Flex justifyContent="center" alignItems="center" flex=".7" flexDirection="column">
        <Text flex=".5" px={2} textAlign="center" color="dark.main" fontSize={1}>
          Bar, salle de restaurant, salle de concert ou spa, <b>vous pourrez animer</b> différemment{" "}
          <b>chaque espace !</b>
        </Text>
        <Box flex=".5" height="auto" width="100%" pb={2}>
          <img height="auto" width="100%" src="/img/onboarding/frise.svg" />
        </Box>
      </Flex>
    </Flex>
  );
};

const InnerItem2 = () => {
  return (
    <Flex flexDirection="column" justifyContent="space-between" alignItems="center" height="100%" overflow="hidden">
      <Flex px={1} flex=".3" justifyContent="center" alignItems="center">
        <Text
          as="h2"
          textAlign="center"
          fontWeight="900"
          fontSize="1.3em"
          color="dark.main"
          fontFamily="Poppins"
          letterSpacing="1.04px"
          maxWidth="270px"
        >
          Animez-les en créant des Kards
        </Text>
      </Flex>
      <Flex justifyContent="space-around" alignItems="center" flex=".7">
        <Flex height="100%" flex=".3" flexDirection="column" alignItems="center" justifyContent="flex-start">
          {" "}
          <Text flex=".25" px={1} textAlign="center" color="dark.main" fontSize={1} fontWeight="bold">
            Choisissez un modèle de Kards
          </Text>
          <Flex justifyContent="center" alignItems="flex-start" flex=".75" height="auto" width="100%" maxWidth="155px">
            <img height="auto" width="100%" src="/img/onboarding/template.svg" />
          </Flex>
        </Flex>
        <Flex height="100%" flex=".3" flexDirection="column" alignItems="center" justifyContent="flex-start">
          {" "}
          <Text flex=".25" px={1} textAlign="center" color="dark.main" fontSize={1} fontWeight="bold">
            Personalisez-la avec vos infos
          </Text>
          <Flex justifyContent="center" alignItems="flex-start" flex=".75" height="auto" width="85%" maxWidth="110px">
            <img height="auto" width="100%" src="/img/onboarding/multi.svg" />
          </Flex>
        </Flex>
        <Flex height="100%" flex=".3" flexDirection="column" alignItems="center" justifyContent="flex-start">
          {" "}
          <Text flex=".25" px={1} textAlign="center" color="dark.main" fontSize={1} fontWeight="bold">
            Partagez-la grâce au QRcode
          </Text>
          <Flex justifyContent="center" alignItems="flex-start" flex=".75" height="auto" width="90%" maxWidth="125px">
            <img height="auto" width="100%" src="/img/onboarding/comm.svg" />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const InnerItem3 = () => {
  return (
    <Flex flexDirection="column" justifyContent="space-between" alignItems="center" height="100%" overflow="hidden">
      <Flex px={1} flex=".3" justifyContent="center" alignItems="center">
        <Text
          as="h2"
          textAlign="center"
          fontWeight="900"
          fontSize="1.3em"
          color="dark.main"
          fontFamily="Poppins"
          letterSpacing="1.04px"
        >
          Profitez du live assistant
        </Text>
      </Flex>
      <Flex justifyContent="center" alignItems="center" flex=".7" flexDirection="column">
        <Text flex=".3" px={2} textAlign="center" color="dark.main" fontSize={1}>
          Connectez-vous à partir de votre mobile, gérer vos kards et <b>interagissez en direct !</b>
        </Text>
        <Flex flex=".7" height="100%" width="auto" flexDirection="column" justifyContent="flex-end" alignItems="center">
          <img src="/img/onboarding/mobile.svg" style={{ maxHeight: "170px", width: "100%", height: "100%" }} />
        </Flex>
      </Flex>
    </Flex>
  );
};
