import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import { firebaseConfig } from "./firebaseConfig";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const shouldUseEmulator = process.env.REACT_APP_FIRESTORE_EMULATOR_HOST || (window as any).Cypress;

if (shouldUseEmulator) {
  const firestoreSettings = {
    // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
    ssl: false,
    host: "localhost:8080",
    experimentalForceLongPolling: true,
  };
  console.debug(`Admin Using Firestore emulator: ${firestoreSettings.host}`, firestoreSettings, firebase.SDK_VERSION);
  firebase.firestore().settings(firestoreSettings);
  firebase.auth().useEmulator("http://localhost:9099");
  firebase
    .app()
    .functions("europe-west1")
    .useEmulator("localhost", 5001);
}

export { firebase };
export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.app().functions("europe-west1");

db.settings({ ignoreUndefinedProperties: true });
