import "react-virtualized/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Suspense, lazy, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as ThemeUiProvider } from "theme-ui";
import { ErrorBoundary } from "src/common-ui/ErrorBoundary";
import { NotificationProvider } from "src/layout/Notification";
import { useGetEstablishmentAuth } from "src/app/useGetEstablishmentAuth";
import { themeUi } from "src/app/Theme";
import { LoginRoutes } from "src/app/LoginRoutes";
import { AppProvider, useRole } from "./app/AppContext";
import { OnboardingWelcome } from "./onboarding/OnboardingWelcome";
import { AppLoader } from "./app/AppLoader";
import useSWR, { SWRConfig } from "swr";
import { db } from "./firebase";
import { useIsMobile } from "src/helpers/Breakpoints";

import "./App.css";

const DesktopApp = lazy(() => import("src/app/DesktopApp"));
const MobileApp = lazy(() => import("src/app/MobileApp"));

export default function App() {
  const { establishment, token, loading, groupe } = useGetEstablishmentAuth();
  const isMobile = useIsMobile();
  const [menuWrapped, setMenuWrapped] = useState<boolean>(false);

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        focusThrottleInterval: 1000 * 10,
      }}
    >
      <ThemeUiProvider theme={themeUi}>
        <ThemeProvider theme={themeUi}>
          <ErrorBoundary>
            <NotificationProvider>
              <AppProvider value={{ establishment, token, groupe, menuWrapped, setMenuWrapped }}>
                <Router>
                  {!loading && token && establishment?.onBoarding === true && !establishment?.type && (
                    <Redirect to={`/establishments/${establishment.id}/welcome`} />
                  )}
                  {/* not load conditionnaly onboarding true to false not change auth state so route does'nt exist */}
                  <Route exact path={"/establishments/:establishmentId/welcome"}>
                    <OnboardingWelcome />
                  </Route>
                  <LoginRoutes />
                  {!loading &&
                    !establishment &&
                    !window.location.href?.includes("login") &&
                    !window.location.href?.includes("create-account") && <Redirect to={`/login`} />}
                  {!loading && establishment && !isMobile && !establishment?.onBoarding && (
                    <Redirect to={`/onboarding/${establishment?.id}`} />
                  )}

                  {!loading && establishment && !isMobile && establishment?.onBoarding && (
                    <>
                      <Route exact path="/">
                        <Redirect to={`/establishments/${establishment?.id}/dashboard`} />
                      </Route>
                      <Route exact path="/establishments">
                        <Redirect to={`/establishments/${establishment?.id}/dashboard`} />
                      </Route>
                      <Route exact path="/establishments/:establishmentId">
                        <Redirect to={`/establishments/${establishment?.id}/dashboard`} />
                      </Route>
                    </>
                  )}

                  {!loading && establishment && isMobile && establishment?.onBoarding && (
                    <Redirect to={`/establishments/${establishment?.id}/m`} />
                  )}

                  <Route path="/establishments/:establishmentId">
                    {!loading && establishment && establishment?.onBoarding && <Secure token={token} />}
                  </Route>

                  <Suspense fallback={<AppLoader />}>{isMobile ? <MobileApp /> : <DesktopApp />}</Suspense>
                </Router>
              </AppProvider>
            </NotificationProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </ThemeUiProvider>
    </SWRConfig>
  );
}

function Secure({ token }) {
  const { establishmentId } = useParams<{ establishmentId: string }>();
  const role = useRole();

  if (role === "super-admin") return null;
  if (role === "groupe") return <HaveToRedirect token={token} type="groupes" />;
  if (role === "agency") return <HaveToRedirect token={token} type="agencies" />;
  if (establishmentId === token?.claims?.establishment) return null;
  return <Redirect to="/" />;
}

function HaveToRedirect({ token, type }) {
  const { establishmentId } = useParams<{ establishmentId: string }>();
  const { data } = useSWR<{ establishmentIds: string[] }>(`/${type}/${token?.claims?.establishment}`, () =>
    db
      .collection(type)
      .doc(token?.claims?.establishment)
      .get()
      .then((doc) => ({ ...doc?.data(), id: doc.id } as any)),
  );

  if (!data) return null;
  if (!data?.establishmentIds) return null;
  if ([...data?.establishmentIds, token?.claims?.establishment].includes(establishmentId)) return null;
  return <Redirect to="/" />;
}
