import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormFinal } from "src/common-ui/Form";
import { Payment } from "./Payment";
import { Coupon } from "./Coupon";
import { Loading } from "src/common-ui/Loading";
import { useGetEstablishmentWithToken } from "src/data/useGetEstablishment";
import { functions } from "src/firebase";
import { useRole } from "src/app/AppContext";

type Steps = "choiceSpace" | "choiceTemplate" | "coupon" | "payment";

type DataOnBoarding = {
  spaces: {
    name: string;
    type: string;
  }[];
};

export function OnBoarding() {
  const { establishmentId }: { establishmentId: string } = useParams();
  const { data: establishment } = useGetEstablishmentWithToken();
  const role = useRole();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const group = role === "groupe";

  useEffect(() => {
    if (establishment?.onBoarding) history.push("admin");
  }, [establishment, history]);

  async function submit(values: DataOnBoarding) {
    try {
      setLoading(true);
      // functions.useFunctionsEmulator("http://localhost:5000");
      const response = await functions.httpsCallable("onBoarding")(values);
      if (response?.data?.code || response?.data?.code === "internal") {
        setLoading(false);
      } else {
        if (group) {
          return history.push(`/establishments/${establishmentId}/dashboard`);
        }
        history.push(`/establishments/${establishmentId}/welcome`);
      }
    } catch (e) {
      console.error("error", e);
    } finally {
      setLoading(false);
    }
  }

  return <FormFinal onSubmit={submit}>{() => <OnboardingStepper group={group} loading={loading} />}</FormFinal>;
}

function OnboardingStepper({ loading, group }) {
  const [step, setStep] = useState<Steps>("coupon");
  if (loading) return <Loading />;

  switch (step) {
    case "coupon":
      return <Coupon isComplete={group ? true : false} onNext={group ? null : () => setStep("payment")} />;
    case "payment":
      return <Payment isComplete onBack={() => setStep("coupon")} />;
    default:
      return null;
  }
}
