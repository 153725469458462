import { TextGreyBold } from "src/common-ui";
import styled from "styled-components";
import { Flex } from "reflexbox";

export const LoadingContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
  background: rgba(230, 230, 230, 0.7);
`;

export const Loading = ({ text = "Initialisation de KARDS en cours...", ...props }) => (
  <LoadingContainer {...props}>
    <TextGreyBold style={{ margin: "50px" }}>{text}</TextGreyBold>
    <div className="loader" id="loader-1" style={{ color: "#6977F5" }} />
  </LoadingContainer>
);

export const Loader = ({ size = "40px", ...props }) => (
  <Flex justifyContent="center" alignItems="center" width="100%" flexDirection="column" height="100%" {...props}>
    <span className="loader" id="loader-1" style={{ color: "inherit", height: size, width: size }} />
    {props.children}
  </Flex>
);
