import { useEffect, useState } from "react";
import { OnBoardingLayout, OnBoardingLayoutProps } from "./OnBoardingLayout";
import { Box } from "reflexbox";
import { useField } from "react-final-form";
import { functions } from "src/firebase";
import styled from "styled-components";

export function debounce(f, interval, timer, setTimer) {
  return (...args) => {
    clearTimeout(timer);
    return new Promise((resolve) => {
      setTimer(setTimeout(() => resolve(f(...args)), interval));
    });
  };
}

type CouponProps = Omit<OnBoardingLayoutProps, "children" | "title">;

export function Coupon({ onNext, onBack, ...props }: CouponProps) {
  const { input } = useField("coupon");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [validCoupon, setValidCoupon] = useState(false);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    async function checkCoupon() {
      setLoading(true);
      const { data } = await functions.httpsCallable("checkCoupon")({
        coupon: input.value,
      });
      if (data === "coupon_not_available") {
        setError(true);
        setValidCoupon(false);
      } else {
        setValidCoupon(true);
        setError(false);
      }
      setLoading(false);
    }

    if (input.value) {
      debounce(checkCoupon, 500, timer, setTimer)();
    }
  }, [input?.value]);

  return (
    <OnBoardingLayout
      disabledNextBtn={!validCoupon}
      onNext={onNext}
      onBack={onBack}
      title="- Quel est votre code partenaire ?"
      {...props}
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="20px"
        height="100%"
        flexDirection="column"
        sx={{ position: "relative" }}
      >
        <Box display="flex" style={{ margin: "10px" }}>
          <Input
            {...input}
            onKeyPress={(event) => {
              if (event.charCode === 13) {
                event?.preventDefault();
              }
            }}
            onChange={input.onChange}
            placeholder="Saisir code partenaire"
          />
        </Box>
        <Box sx={{ position: "absolute", bottom: "-15px" }}>
          {loading && <p>Vérification...</p>}
          {!loading && error && <p style={{ textAlign: "center", color: "#ff7b7b" }}>Ce code n'existe pas </p>}
          {!loading && validCoupon && <p style={{ textAlign: "center", color: "#66b266" }}>Code valide </p>}
        </Box>
      </Box>
    </OnBoardingLayout>
  );
}

export const Input = styled.input`
  ${({ theme }) => `
    border: 1px solid #D9D9D9;
    padding: 12px 14px;
    outline: none;
    font-size: 1.9em;
    border-radius: 4px;
    background-color: white;
    color: ${theme?.colors?.secondary?.dark} !important;
    box-sizing: border-box;
    ::placeholder {
      color: ${theme?.colors?.secondary?.main};
    }
  `}
`;
