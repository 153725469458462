import { useState, useMemo } from "react";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from "@stripe/react-stripe-js";
import styled from "styled-components";
import { Box } from "reflexbox";
import { useField } from "react-final-form";

const createOptions = ({ color, placeHolderColor }) => ({
  style: {
    base: {
      color,
      fontWeight: "600",
      fontSize: "16px",
      border: `1px solid ${color}`,
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      ":focus": {
        color: color,
      },

      "::placeholder": {
        color: placeHolderColor,
      },

      ":focus::placeholder": {
        color: "lightgrey",
      },
    },
    invalid: {
      color: "#FA755A",
      ":focus": {
        color: "#FA755A",
      },
      "::placeholder": {
        color: "#FA755A",
      },
    },
  },
});

export type CheckoutFormProps = {
  onSubmit: (props: {
    stripe: any;
    cardName: string;
    cardNumberElement: any;
    cardExpiryElement: any;
    cardCvcElement: any;
  }) => void;
};

export const CheckoutForm = ({ setIsValide }: any) => {
  const { input } = useField("payment_method");

  const elements = useElements();
  const stripe = useStripe();

  const [cardValidation, setCardValidation] = useState<any>({});
  const options = useMemo(() => createOptions({ color: "grey", placeHolderColor: "grey" }), []);

  const handleChange = async (e) => {
    setCardValidation((c) => ({
      ...c,
      [e.elementType]: {
        isValid: !e.error,
        error: e.error ? e.error.message : "",
      },
    }));
    const isCardValid =
      cardValidation.cardNumber &&
      cardValidation.cardNumber.isValid &&
      cardValidation.cardExpiry &&
      cardValidation.cardExpiry.isValid &&
      cardValidation.cardCvc &&
      cardValidation.cardCvc.isValid;
    const cardNumberElement = elements.getElement(CardNumberElement);
    if (isCardValid) {
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
      });
      if (paymentMethod) {
        setIsValide(isCardValid);
        input.onChange(paymentMethod);
      }
    }
  };

  return (
    <Box width="50%" padding="20px">
      <Box>
        <Box display="flex" width="100%" style={{ margin: "10px" }}>
          <Box display="flex" flex="0.3" color="#3d4244">
            Carte Bancaire
          </Box>
          <Box display="flex" flex="0.7" justifyContent="space-evenly">
            <Box> {visa}</Box>
            <Box> {master}</Box>
            <Box> {amex}</Box>
          </Box>
        </Box>
        <Box display="flex" style={{ margin: "30px 0" }}>
          <InputWrapper width="100%" color="dark">
            <CardNumberElement data-cy="number-element" options={options} onChange={handleChange} />
          </InputWrapper>
        </Box>
        <Box display="flex" style={{ justifyContent: "space-between", margin: "30px 0" }}>
          <InputWrapper width="45%">
            <CardExpiryElement data-cy="expiry-element" options={options} onChange={handleChange} />
          </InputWrapper>
          <InputWrapper width="45%">
            <CardCvcElement data-cy="cvc-element" options={options} onChange={handleChange} />
          </InputWrapper>
        </Box>
      </Box>
    </Box>
  );
};

const InputWrapper = styled.div<{ width: string }>`
  padding: 10px 20px 11px;
  margin: 10px;
  border: 1px solid grey;
  color: grey;
  border-radius: 5px;
  width: ${(props) => props.width};
  color: ${(props) => props.color};
`;

const visa = (
  <svg width="35" height="25" viewBox="0 0 35 25" xmlns="http://www.w3.org/2000/svg">
    <title>Visa</title>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M0 4h35v17H0z"></path>
      <path fill="#191E70" d="M4 0h27a4 4 0 0 1 4 4H0a4 4 0 0 1 4-4z"></path>
      <path fill="#F7B600" d="M0 21h35a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z"></path>
      <path
        fill="#191E70"
        fillRule="nonzero"
        d="M16.86 8.22l-2.02 9.7h-2.46l2.02-9.7h2.46zm10.16 6.27l1.3-3.66.72 3.66h-2.02zm2.74 3.43H32l-1.95-9.7h-2.09c-.43 0-.86.3-1 .75l-3.61 8.95h2.52l.5-1.42h3.1l.3 1.42zm-6.34-3.2c0-2.54-3.4-2.7-3.4-3.81.08-.52.51-.82 1.02-.82a4.4 4.4 0 0 1 2.38.45l.43-2.1A5.97 5.97 0 0 0 21.62 8c-2.38 0-4.12 1.34-4.12 3.2 0 1.43 1.23 2.17 2.1 2.62.93.45 1.3.75 1.22 1.2 0 .66-.72.96-1.44.96-.87 0-1.73-.22-2.52-.6l-.44 2.1c.87.37 1.8.52 2.67.52 2.67.07 4.33-1.27 4.33-3.29zm-10.03-6.5l-3.89 9.7H6.9l-1.94-7.76c0-.37-.3-.67-.58-.82A8.21 8.21 0 0 0 2 8.52l.07-.3h4.11c.58 0 1.01.45 1.08.97l1.01 5.6 2.6-6.57h2.52z"
      ></path>
    </g>
  </svg>
);

const master = (
  <svg width="35" height="25" viewBox="0 0 35 25" xmlns="http://www.w3.org/2000/svg">
    <title>Mastercard</title>
    <g fill="none" fillRule="evenodd">
      <rect width="35" height="25" fill="#353A48" rx="4"></rect>
      <circle cx="12.5" cy="12.5" r="7.5" fill="#ED0006"></circle>
      <circle cx="22.5" cy="12.5" r="7.5" fill="#F9A000"></circle>
      <path fill="#FF5D00" d="M17.5 6.91a7.48 7.48 0 0 1 0 11.18 7.48 7.48 0 0 1-.06-11.13z"></path>
    </g>
  </svg>
);

const amex = (
  <svg width="35" height="25" viewBox="0 0 35 25" xmlns="http://www.w3.org/2000/svg">
    <title>American Express</title>
    <g fill="none" fillRule="evenodd">
      <rect width="35" height="25" fill="#017ECD" rx="4"></rect>
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M17.57 12.47v-.68c0-.26-.27-.53-.53-.53h-.75v1.21h-.8V9.05h1.97c.49-.05.97.27 1.02.74v.26c0 .32-.16.63-.48.8.27.15.43.47.37.78v.84h-.8zm-1.33-2h.85c.16.06.37-.05.43-.2.05-.16-.06-.38-.22-.43h-1.06v.63zm11.38 2l-1.29-2.31v2.31h-1.6l-.27-.73h-1.5l-.26.73H21s-1.08-.15-1.08-1.63c0-1.79.91-1.84 1.28-1.84h1.02v.79h-.8c-.38.05-.64.37-.59.74v.26c0 1.16 1.39.79 1.44.79L23.3 9h1.12l1.23 3.1V9.06h1.12l1.28 2.27V9.05h.8v3.48l-1.22-.06zm-4.28-1.58h.8l-.37-1-.43 1zm-12.13 1.58v-2.36l-1.01 2.36h-.64l-1.02-2.36v2.36h-1.6l-.27-.73h-1.5l-.26.73H4L5.34 9h1.12l1.23 3.16v-3.1h1.28l.9 2.15.92-2.16h1.28v3.48l-.86-.06zM5.5 10.9h.8l-.38-1-.42 1zm7 1.58V9.05H15v.8h-1.7v.52h1.65v.79H13.3v.58H15v.73h-2.5zm6.3.06V9.05h.8v3.48h-.8zm1.76 4.42v-.69c0-.31-.26-.52-.58-.52h-.86v1.2h-.85v-3.41h2.08a.89.89 0 0 1 1.07.79v.2c0 .32-.16.64-.48.8.26.15.42.47.42.79v.84h-.8zm-1.39-2h.91c.16.05.38-.06.43-.21.05-.16-.05-.37-.21-.42a.16.16 0 0 0-.22 0h-.9v.63zm-4.32 2h-.75l-.91-1-.9 1H9.07v-3.42h3.15l.96 1.1 1.02-1.1h2.72c.53-.06 1.02.26 1.07.79v.2c0 1-.38 1.32-1.5 1.32h-.85v1.1h-.8zm-1.07-1.69l1.07 1.21v-2.36l-1.07 1.15zm-3.85.95h1.82l.85-.95-.85-.94H9.93v.52h1.76v.8H9.93v.57zm5.77-1.26h.91c.16.05.37-.06.43-.21.05-.16-.06-.37-.22-.42a.16.16 0 0 0-.21 0h-.9v.63zm10.74 2H24.9v-.8h1.34s.48.06.48-.26c0-.31-.75-.26-.75-.26-.59.1-1.07-.31-1.18-.84v-.16c-.05-.52.38-1 .91-1.05h1.82v.74h-1.34s-.48-.1-.48.26c0 .26.64.26.64.26s1.34-.1 1.34.95a1.1 1.1 0 0 1-.96 1.21h-.22l-.05-.05zm-4.6 0v-3.42h2.68v.79H22.7v.52h1.76v.8H22.7v.57h1.82v.74h-2.67zm7.6 0h-1.56v-.8h1.34s.48.06.48-.26c0-.31-.75-.26-.75-.26-.59.1-1.07-.31-1.17-.9v-.15c-.06-.53.37-1 .9-1.05h1.82v.79h-1.33s-.49-.1-.49.26c0 .26.65.26.65.26s1.33-.1 1.33.95A1.1 1.1 0 0 1 29.7 17h-.21c0-.05 0-.05-.06-.05z"
      ></path>
    </g>
  </svg>
);
