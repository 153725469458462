import { useState } from "react";
import styled from "styled-components";
import { Button, ModalFullScreen, Loading } from "src/common-ui";
import { Flex, Box } from "reflexbox";

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  background: #fcfcfc;
  z-index: 0;
`;

const ContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 5px;
`;

const LinkFooter = styled.a`
  color: black;
  cursor: pointer;
  font-size: 0.8em;
  text-decoration: none;
`;

export function ContainerForm({ children, loading = false, setFormType = null, formType = null, height = "100vh" }) {
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    return setOpenModal(false);
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height={height}
      sx={{ position: "relative" }}
      backgroundColor="white.main"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        sx={{
          transform: "rotate(10deg)",
          zIndex: "0",
          position: "absolute",
        }}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          sx={{ position: "absolute", left: "-40px" }}
        >
          <Line bg="primary.main" height="20px" width="25vw" />
          <Line bg="primary.light" height="60px" width="29vw" />
          <Line bg="secondary.light" height="40px" width="20vw" border="1px solid" />
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-end"
          sx={{ position: "absolute", right: "-40px" }}
        >
          <Line bg="secondary.light" height="40px" width="25vw" border="1px solid" />
          <Line bg="primary.main" height="20px" width="20vw" />
          <Line bg="primary.light" height="60px" width="29vw" />
        </Flex>
      </Flex>
      {loading && <Loading text="" />}
      <Flex sx={{ position: "absolute", top: "10px", right: "20px" }}>
        {formType && formType !== "signin" && <Button onClick={() => setFormType("signin")}>Déjà un compte ?</Button>}
      </Flex>
      {children}
      <Footer>
        <ContentFooter>
          <LinkFooter href="https://kards.fr/" target="blank">
            © Kards
          </LinkFooter>
          <Box p={2} />
        </ContentFooter>
      </Footer>
      <ModalFullScreen open={openModal} onClose={handleClose}>
        <iframe src="../assets/sample.pdf" style={{ width: "90%", height: "100%" }} />
      </ModalFullScreen>
    </Flex>
  );
}

function Line(props) {
  return (
    <Box
      {...props}
      sx={{ boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2)", border: props.border, borderColor: "secondary.main" }}
    />
  );
}
