import { useEffect, useState } from "react";
import { Establishment, Groupe } from "@kards/types";
import { auth, db } from "src/firebase";

export function useGetEstablishmentAuth() {
  const [loading, setLoading] = useState<boolean>(true);
  const [establishment, setEstablishment] = useState<Establishment & { id: string }>();
  const [groupe, setGroupe] = useState<Groupe>();
  const [tokenUser, setToken] = useState<any>();

  useEffect(() => {
    async function getEstablishmentAuth() {
      const token = await auth.currentUser?.getIdTokenResult();

      if (token?.claims?.establishment) {
        setLoading(false);
        const establishment = await db
          .collection("establishments")
          .doc(token?.claims?.establishment)
          .get()
          .then((doc) => ({ id: doc?.id, ...doc.data() } as Establishment & { id: string }));
        if (token?.claims.role === "groupe") {
          const g = await getGroupe(token?.claims?.establishment);
          setGroupe(g);
        }
        return setEstablishment(establishment);
      } else {
        setEstablishment(null);
      }

      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const token = await user.getIdTokenResult();

          if (token?.claims?.establishment) {
            const establishment = await db
              .collection("establishments")
              .doc(token?.claims?.establishment)
              .get()
              .then(
                (doc) =>
                  ({ id: doc.id, ...doc.data() } as Establishment & {
                    id: string;
                  }),
              );

            if (token?.claims.role === "groupe") {
              const g = await getGroupe(token?.claims?.establishment);
              setGroupe(g);
            }
            setToken(token);
            setEstablishment(establishment);
          } else {
            setEstablishment(null);
          }
        } else {
          setEstablishment(null);
        }
        setLoading(false);
      });
    }

    getEstablishmentAuth();
  }, []);

  return { establishment, token: tokenUser, loading, groupe };
}

async function getGroupe(establishmentId: string) {
  const groupe = await db
    .collection("groupes")
    .doc(establishmentId)
    .get()
    .then((doc) => ({ ...doc.data(), id: doc.id } as Groupe));
  return groupe?.id ? groupe : null;
}
